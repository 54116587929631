/* WeatherCard.css */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.weatherContainer {
  background: linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  color: #000000;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.mainInfo h2 {
  color: #000000;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 700;
}

.temperatureSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  margin-right: 30px;
}

.temperatureValue {
  font-size: 3rem;
  margin-left: 10px;
  font-weight: 700;
}

.weatherIcon {
  margin-top: 20px;
  font-size: 2rem;
  font-weight: 700;
}

.sunInfo {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  font-size: 1.5rem;
}

.sunrise_data {
  color: rgb(255, 153, 0);
}

.weatherDetailsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.detailItem {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  backdrop-filter: blur(5px);
  color: #000000;
  font-weight: 400;
}

.detailItem svg {
  margin-bottom: 5px;
  color: #000000;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .weatherContainer {
    padding: 15px;
    /* align-items: center;
    justify-content: center; */
  }

  .temperatureValue {
    font-size: 2.5rem;
  }

  .sunInfo {
    flex-direction: column;
    font-size: 1.2rem;
  }

  .weatherDetailsGrid {
    grid-template-columns: 1fr;
    align-items: center;
    text-align: center;
    align-self: center;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    background-color: rgba(255, 215, 0, 0.3);
  }
  50% {
    transform: scale(1.1);
    background-color: rgba(255, 215, 0, 0.5);
  }
}

/* Icon animations for other details */
.detailItem svg {
  margin-bottom: 5px;
  color: #000000;
}

.temperatureSection svg {
  animation: rotateThermometer 5s linear infinite;
}

@keyframes rotateThermometer {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animation for other weather condition icons */
.weatherIcon .icon-bounce {
  animation: bounceIcon 1.5s ease-in-out infinite;
}

@keyframes bounceIcon {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Fading effect for cloud icons */
.weatherIcon .icon-fade {
  animation: fadeCloud 2s ease-in-out infinite;
}

@keyframes fadeCloud {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .weatherContainer {
    padding: 15px;
  }

  .temperatureValue {
    font-size: 2.5rem;
  }

  .sunInfo {
    flex-direction: column;
    font-size: 1.2rem;
  }

  .weatherDetailsGrid {
    grid-template-columns: 1fr;
    align-items: center;
    text-align: center;
  }
}
